<template>
    <div id="accreditList">
        <navbar title="申请列表"></navbar>
        <template v-if="ListData.length>0">
            <div class="accreditList-content" v-for="(item,index) in ListData" :key="index">
                <div class="rows">
                    <div>申请时间：{{item.applyTime}}</div>
                    <div style="color:rgba(153, 153, 153, 1);">{{item.accreditState}}</div>
                </div>
                <div class="row">被授权公司/个人：{{item.Company_Individual}}</div>
                <div class="row">被授权项目：{{item.Licensed_Project}}</div>
                <div class="row">被授权产品：<span v-for="(items,index) in item.Licensed_Products" :key="index">{{items}};</span></div>
                <div class="row" v-if="item.remark!=null">驳回原因：{{item.remark}}</div>
                <div class="rows" v-if="item.accreditState=='通过'">
                    <div>被授权时间：{{item.Licensed_beginTime}}~{{item.Licensed_endTime}}</div>
                    <div @click="seeCertificate(item)" style="color:rgba(231, 83, 20, 1);cursor: pointer;">查看证书</div>
                </div>
                <div class="line"></div>
            </div>
        </template>
        <div v-else class="EmptyState">暂无授权申请</div>
        <div style="height:65px;width:100%"></div>
        <div class="applyFor">
            <div class="btn" @click="goAccreditEdit">
                新增申请
            </div>
        </div>
    </div>
</template>

<script>
// const moment = require("moment");
let moment = require("moment");
import navbar from "COMPS/accredit_navbar.vue";
import { queryAtPage } from "../../api/api";
export default {
    data() {
        return {
            ListData: [],
        };
    },
    components: {
        navbar,
    },
    mounted() {
        let userId = this.$store.state.userData.userId;
        queryAtPage(userId).then((data) => {
            console.log(data);
            this.ListData = data.data.list.map((e) => {
                return {
                    id: e.impowerId,
                    impowerCode:e.impowerCode,
                    applyTime: moment(e.applyTime).format("YYYY-MM-DD"),
                    accreditState:
                        e.auditState == "awaiting"
                            ? "待审核"
                            : e.auditState == "success"
                            ? "通过"
                            : "驳回",
                    Company_Individual: e.impowerUnit,
                    remark:e.remark,
                    Licensed_Project: e.impowerGoods,
                    Licensed_Products: e.impowerProject.split("、"),
                    Licensed_beginTime: e.beginTime==null?'':moment(e.beginTime).format(
                        "YYYY-MM-DD"
                    ),
                    Licensed_endTime: e.endTime==null?'':moment(e.endTime).format("YYYY-MM-DD"),
                };
            });
        });
    },
    methods: {
        goAccreditEdit() {
            this.$router.push({
                path: "/accreditEdit",
            });
        },
        seeCertificate(item) {
            console.log(item);
            this.$router.push({
                path: "/accreditCanvas",
                query:{
                    accreditData:item
                }
            });
        },
    },
};
</script>
<style scoped>
#accreditList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.accreditList-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    padding: 16px 20px;
}
.rows {
    width: 100%;
    display: flex;
    flex-direction: row;
    line-height: 30px;
    justify-content: space-between;
    align-items: center;
    white-space: pre-wrap;
}
.row {
    line-height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: pre-wrap;
    flex-wrap: wrap;
    text-align: left;
}
.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(218, 218, 218, 1);
    margin-top: 16px;
}

.applyFor {
    border-top: 1px solid rgba(218, 218, 218, 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
    height: 82px;
    width: 100%;
    background: white;
}
.btn {
    width: 335px;
    height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(255, 96, 29, 1);
    border-radius: 20px;
    font-size: 14px;
}
.EmptyState {
    margin-top: 60%;
    font-size: 13px;
    color: rgba(153, 153, 153, 1);
}
</style>